import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { FormProvider } from 'react-hook-form';
import CloseIcon from '@mui/icons-material/Close';
import LoadingButton from '@mui/lab/LoadingButton';
import CustomTextInput from 'components/Forms/CustomTextInput';
import LabelRequired from 'components/FormFields/LabelRequired';
import { StyledAction, StyledModalTitle } from 'components/Order/OrderStatusChange/styles';
import DraggableDialogWrapper from 'components/DraggableDialogWrapper/DraggableDialogWrapper';
import { useDeliveryCancel } from 'components/Order/DeliveryOrderCancelModal/hooks/useDeliveryCancel';
import { Dialog, DialogTitle, IconButton, DialogContent, Grid, Button, DialogActions } from '@mui/material';

const CustomDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2, pl: 3 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 16,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

const DeliveryOrderCancelModal = ({ cancelDeliveryModal, closeDeliveryCancelModal, data }) => {
  const { t } = useTranslation();
  const { formDeliveryCancelMethods, loading, handleSubmit } = useDeliveryCancel(
    cancelDeliveryModal,
    data,
    closeDeliveryCancelModal
  );

  return (
    <Dialog
      open={cancelDeliveryModal}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth={true}
      disableEscapeKeyDown={true}
      onClose={closeDeliveryCancelModal}
      PaperComponent={DraggableDialogWrapper}
      PaperProps={{ sx: { width: '538px', maxWidth: '100%', background: '#FAFAFA', cursor: 'grab', height: '350px' } }}
    >
      <FormProvider {...formDeliveryCancelMethods}>
        <form onSubmit={formDeliveryCancelMethods.handleSubmit(handleSubmit)}>
          <CustomDialogTitle id="customized-dialog-title" onClose={loading ? undefined : closeDeliveryCancelModal}>
            <StyledModalTitle>{t('Cancel Delivery')}</StyledModalTitle>
          </CustomDialogTitle>
          <DialogContent sx={{ width: '100%' }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <CustomTextInput
                  sx={{ width: '100%', height: '156px', mt: 2 }}
                  name="comment"
                  multiline={true}
                  rows={6}
                  label={<LabelRequired>{t('Note')}</LabelRequired>}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions sx={{ width: '100%' }}>
            <StyledAction sx={{ mr: 2 }}>
              <Button
                color="primary"
                variant="outlined"
                sx={{ border: 'none', color: '#626A7A', '&:hover': { border: 'none' } }}
                onClick={closeDeliveryCancelModal}
              >
                {t('confirm_modal.cancel')}
              </Button>
              <LoadingButton
                loading={loading}
                type="submit"
                color="primary"
                variant="contained"
                sx={{ width: '200px' }}
                disabled={!formDeliveryCancelMethods.formState.isValid || loading}
              >
                {t('Cancel Delivery')}
              </LoadingButton>
            </StyledAction>
          </DialogActions>
        </form>
      </FormProvider>
    </Dialog>
  );
};

export default memo(DeliveryOrderCancelModal);
