// RedirectionRoute.js
import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const RedirectionRoute = ({ component: Component, to, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => (
        <Redirect
          to={{
            pathname: to,
            state: { from: props.location }, // Pass the original location
          }}
        />
      )}
    />
  );
};
export default RedirectionRoute;
