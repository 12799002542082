import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import Private from 'components/Private';
import { useTranslation } from 'react-i18next';
import { addModifier } from 'redux/actions/Menu/modifiers';
import { useDispatch } from 'react-redux';

const FormHeader = ({ title, handleDelete, mode, currentModifier, currentProduct, setLoadingDuplicate = () => {} }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const delateModifierGroup = () => {
    setAnchorEl(null);
    handleDelete();
  };

  const duplicateModifierGroup = () => {
    handleClose();
    setLoadingDuplicate(true);
    const newModifier = {
      ...currentModifier,
      name: `Copy-${currentModifier?.name}`,
    };
    delete newModifier.id;
    delete newModifier._id;
    dispatch(addModifier({ ...newModifier }))
      .then((res) => {
        history.push({
          search: ``,
        });
      })
      .finally(() => {
        setLoadingDuplicate(false);
      });
  };
  const itemChangesModifierGroup = () => {};

  const goBack = () => {
    history.push('/menu/modifierGroups');
  };

  const isInOverview = history?.location.pathname?.includes('/overview');

  return (
    <Box
      sx={{
        px: 4,
        py: 3,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderBottom: 1,
        borderColor: 'divider',
      }}
    >
      <Typography variant="h6">
        {!isInOverview && <KeyboardBackspaceIcon sx={{ mr: 2, cursor: 'pointer' }} onClick={goBack} />}
        {title || t('menu.add_new_modifier')}
      </Typography>
      <Private permission="MENU_EDIT">
        {mode === 'edit' && (
          <>
            <IconButton sx={{ ml: 1 }} disableRipple onClick={handleClick}>
              <MoreVertIcon />
            </IconButton>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              <MenuItem onClick={delateModifierGroup}>{t('settings.delete')}</MenuItem>
              <MenuItem onClick={duplicateModifierGroup}>{t('menu.duplicate')}</MenuItem>
              <MenuItem disabled>{t('menu.see_item_changes')}</MenuItem>
            </Menu>
          </>
        )}
      </Private>
    </Box>
  );
};

export default FormHeader;
