import { DEFAULT_TIMEZONE, StatusEnum } from 'components/Chargeback/constants';
import { GREATER_MSG, LESS_MSG, NAME_ENUMS } from 'constants/products';
import { set } from 'lodash';
import { v4 as uuidv4 } from 'uuid';

export const sumTaxesAndFees = (data) => {
  let sum = 0;
  if (data.charges?.servicingFee && Object.keys(data.charges?.servicingFee).length) {
    let marketplaceFee = data?.charges?.servicingFee?.marketplaceFee || 0;
    let ordersCoFee = data?.charges?.servicingFee?.ordersCoFee || 0;
    let processingFee = data?.charges?.servicingFee?.processingFee || 0;
    sum = Number(marketplaceFee) + Number(ordersCoFee) + Number(processingFee);
  }
  return sum;
};

export const validationModifierCustomersCan = (formState, copyFormErrors, formErrors, updateFormErrors, name) => {
  const optionsCount = formState.list?.length;
  const minT = Number(formState.minTotalNumberOfModifierItems);
  const maxT = Number(formState.maxTotalNumberOfModifierItems);
  const minAnySingle = Number(formState.minAnySingleModifierItems);
  const maxAnySingle = Number(formState.maxAnySingleModifierItems);
  const free = Number(formState.free);
  const min = Number(formState.min);
  const max = Number(formState.max);
  const currentValue = Number(formState[name]);
  const maxN = 100000;
  switch (name) {
    case NAME_ENUMS.min:
      if (min > maxN) {
        copyFormErrors = set(formErrors, name, {
          error: true,
          message: `menu.max_number`,
        });
      } else {
        copyFormErrors = set(formErrors, name, {
          error: false,
          message: '',
        });
      }
      if (max && currentValue > max) {
        copyFormErrors = set(formErrors, name, {
          error: true,
          message: `${GREATER_MSG} ${max}`,
        });
      } else {
        if (max !== 0) {
          copyFormErrors = set(formErrors, NAME_ENUMS.max, {
            error: false,
            message: '',
          });
        }
      }

      if (max && max < currentValue) {
        copyFormErrors = set(formErrors, NAME_ENUMS.max, {
          error: true,
          message: `${LESS_MSG} ${currentValue}`,
        });
      } else {
        if (max !== 0) {
          copyFormErrors = set(formErrors, NAME_ENUMS.max, {
            error: false,
            message: '',
          });
        }
      }
      if (minT > maxT) {
        copyFormErrors = set(formErrors, NAME_ENUMS.maxT, {
          error: true,
          message: `${LESS_MSG} ${minT}`,
        });
      } else {
        copyFormErrors = set(formErrors, NAME_ENUMS.maxT, {
          error: false,
          message: ``,
        });
      }
      break;
    case NAME_ENUMS.max:
      if (max > maxN) {
        copyFormErrors = set(formErrors, name, {
          error: true,
          message: `menu.max_number`,
        });
      } else {
        copyFormErrors = set(formErrors, name, {
          error: false,
          message: '',
        });
      }

      if (min && currentValue < min) {
        copyFormErrors = set(formErrors, name, {
          error: true,
          message: `${LESS_MSG} ${min}`,
        });
      }

      if (min && min > currentValue) {
        copyFormErrors = set(formErrors, NAME_ENUMS.min, {
          error: true,
          message: `${GREATER_MSG} ${currentValue}`,
        });
      } else {
        if (min !== 0) {
          copyFormErrors = set(formErrors, NAME_ENUMS.min, {
            error: false,
            message: '',
          });
        }
      }
      if (minT <= maxT) {
        copyFormErrors = set(formErrors, NAME_ENUMS.maxT, {
          error: false,
          message: ``,
        });
      }
      break;
    case NAME_ENUMS.minT:
      if (minT > maxN) {
        copyFormErrors = set(formErrors, name, {
          error: true,
          message: `menu.max_number`,
        });
      } else {
        copyFormErrors = set(formErrors, name, {
          error: false,
          message: '',
        });
      }

      if (maxT && currentValue > maxT) {
        copyFormErrors = set(formErrors, name, {
          error: true,
          message: `${GREATER_MSG} ${maxT}`,
        });
      }

      if (maxT && maxT < currentValue) {
        copyFormErrors = set(formErrors, NAME_ENUMS.maxT, {
          error: true,
          message: `${LESS_MSG} ${currentValue}`,
        });
      } else {
        copyFormErrors = set(formErrors, NAME_ENUMS.maxT, {
          error: false,
          message: '',
        });
      }

      break;
    case NAME_ENUMS.maxT:
      if (maxT > maxN) {
        copyFormErrors = set(formErrors, name, {
          error: true,
          message: `menu.max_number`,
        });
      } else {
        copyFormErrors = set(formErrors, name, {
          error: false,
          message: '',
        });
      }

      if (minT && currentValue < minT) {
        copyFormErrors = set(formErrors, name, {
          error: true,
          message: `${LESS_MSG} ${minT}`,
        });
      }

      if (minT && minT > currentValue) {
        copyFormErrors = set(formErrors, NAME_ENUMS.minT, {
          error: true,
          message: `${GREATER_MSG} ${currentValue}`,
        });
      } else {
        copyFormErrors = set(formErrors, NAME_ENUMS.minT, {
          error: false,
          message: '',
        });
      }

      break;
    case NAME_ENUMS.minAnySingle:
      if (minAnySingle > maxN) {
        copyFormErrors = set(formErrors, name, {
          error: true,
          message: `menu.max_number`,
        });
      } else {
        copyFormErrors = set(formErrors, name, {
          error: false,
          message: '',
        });
      }
      if (!minAnySingle || Number(minAnySingle) === 0) {
        copyFormErrors = set(formErrors, name, {
          error: true,
          message: `menu.min_number`,
        });
      }
      if (maxAnySingle < minAnySingle) {
        copyFormErrors = set(formErrors, name, {
          error: true,
          message: `${LESS_MSG} ${maxAnySingle}`,
        });
      }

      if (maxAnySingle && currentValue > maxAnySingle) {
        copyFormErrors = set(formErrors, name, {
          error: true,
          message: `${GREATER_MSG} ${maxAnySingle}`,
        });
      }

      if (maxAnySingle && maxAnySingle < currentValue) {
        copyFormErrors = set(formErrors, NAME_ENUMS.maxAnySingle, {
          error: true,
          message: `${LESS_MSG} ${currentValue}`,
        });
      } else {
        copyFormErrors = set(formErrors, NAME_ENUMS.maxAnySingle, {
          error: false,
          message: '',
        });
      }
      if (!minAnySingle || Number(minAnySingle) === 0) {
        copyFormErrors = set(formErrors, name, {
          error: true,
          message: `menu.min_number`,
        });
      }
      break;
    case NAME_ENUMS.maxAnySingle:
      if (maxAnySingle > maxN) {
        copyFormErrors = set(formErrors, name, {
          error: true,
          message: `menu.max_number`,
        });
      } else {
        copyFormErrors = set(formErrors, name, {
          error: false,
          message: '',
        });
      }

      if (minAnySingle && currentValue < minAnySingle) {
        copyFormErrors = set(formErrors, name, {
          error: true,
          message: `${LESS_MSG} ${minAnySingle}`,
        });
      }

      if (minAnySingle && minAnySingle > currentValue) {
        copyFormErrors = set(formErrors, NAME_ENUMS.minAnySingle, {
          error: true,
          message: `${GREATER_MSG} ${currentValue}`,
        });
      } else {
        copyFormErrors = set(formErrors, NAME_ENUMS.minAnySingle, {
          error: false,
          message: '',
        });
      }

      if (maxAnySingle && !minAnySingle) {
        copyFormErrors = set(formErrors, NAME_ENUMS.minAnySingle, {
          error: true,
          message: `menu.min_number`,
        });
      } else {
        copyFormErrors = set(formErrors, NAME_ENUMS.minAnySingle, {
          error: false,
          message: '',
        });
      }
      if (!maxAnySingle || maxAnySingle === 0) {
        copyFormErrors = set(formErrors, name, {
          error: true,
          message: `menu.min_number`,
        });
      }
      break;
    case NAME_ENUMS.free:
      if (free > maxN) {
        copyFormErrors = set(formErrors, name, {
          error: true,
          message: `menu.max_number`,
        });
      } else {
        copyFormErrors = set(formErrors, name, {
          error: false,
          message: '',
        });
      }
      break;

    default:
      break;
  }
  updateFormErrors({ ...copyFormErrors });
};

export const sortableProvidersByStatus = (arr) => {
  return arr.sort((a, b) => {
    if (a.status < b.status) {
      return 1;
    }
    if (a.status > b.status) {
      return -1;
    }
    return 0;
  });
};

export const groupByCategory = (data) => {
  let sortIndex = 0;
  let groupName = null;
  return data?.reduce((acc, item) => {
    // const ids = item._id || item.externalId || item.internalId || uuidv4();
    if (item?.externalId === '__INFO_LINE__') {
      groupName = item?.name;
    }
    if (item?.category) {
      let categoryId = item.category.name;
      if (groupName) {
        categoryId = `${groupName}__${categoryId}`;
      }
      if (!acc[categoryId]) {
        acc[categoryId] = {
          items: [],
          categoryName: item.category.name,
        };
      }
      acc[categoryId].items.push(item);
    } else {
      let ids = sortIndex;
      if (groupName) {
        ids = `${groupName}__${sortIndex}`;
      }
      if (!acc[ids]) {
        acc[ids] = {
          items: [],
        };
      }
      acc[ids].items.push(item);
      sortIndex++;
    }

    return acc;
  }, []);
};

export function getOrdinalSuffix(day) {
  if (day > 3 && day < 21) return 'th';
  switch (day % 10) {
    case 1:
      return 'st';
    case 2:
      return 'nd';
    case 3:
      return 'rd';
    default:
      return 'th';
  }
}

export const formatDateByStatus = (dateString, type) => {
  const currentBusiness = dateString?.orderData && dateString?.orderData?.business[0];
  const timeZoneId = currentBusiness?.timezone?.timeZoneId || DEFAULT_TIMEZONE;
  const dateToUse =
    type === StatusEnum.CREATED ? dateString?.requestDate || dateString?.createdAt : dateString?.status?.updatedAt;
  const date = new Date(dateToUse);

  const timeOptions = { hour: 'numeric', minute: '2-digit', hour12: true, timeZone: timeZoneId };
  const dateOptions = { weekday: 'short', month: 'short', day: 'numeric', timeZone: timeZoneId };

  const timePart = new Intl.DateTimeFormat('en-US', timeOptions).format(date);
  const datePart = new Intl.DateTimeFormat('en-US', dateOptions).format(date);

  const day = date.getDate();
  const ordinalSuffix = getOrdinalSuffix(day);

  return `${timePart} ${datePart}${ordinalSuffix}` || '';
};

export const capitalizedWord = (word) => {
  const BRAND = {
    visa: 'VISA',
    mastercard: 'Master Card',
    american_express: 'American Express',
    amex: 'American Express',
    discover: 'Discover',
  };

  if (BRAND[word]) {
    return BRAND[word];
  }
  const resultWithSymbol = word?.toLowerCase().replace(/[_-]/g, ' ');
  const resultWithoutSymbol = word?.charAt(0)?.toUpperCase() + word?.slice(1);
  const lastResult = resultWithSymbol
    ? resultWithSymbol
        .split(' ')
        .map((part) => part.charAt(0).toUpperCase() + part.slice(1))
        .join(' ')
    : resultWithoutSymbol;
  return lastResult || '';
};
