import { useState } from 'react';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { signInV3 } from 'redux/actions/user';
import { useMatchTablet } from './useMatchMobile';
import { yupResolver } from '@hookform/resolvers/yup';
import { useHistory, useLocation } from 'react-router-dom';
import { TOW_FACTOR_BLOCK_MESSAGE } from './useTowFactor';
import useTowFactorModalActions from 'pages/Auth/TwoFactor/hooks/useTowFactorModalActions';

const schemaUserLogin = yup.object().shape({
  email: yup.string().email('this_field_is_invalid_').required('field_is_required_'),
  password: yup
    .string()
    .min(6, 'this_field_min_6_characters_')
    .max(255, 'this_field_max_255_characters_')
    .required('field_is_required_'),
});

export const useLogin = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const {
    towFactorKey,
    setTowFactorKey,
    open,
    setOpen,
    handleClose,
    timerFinished,
    resetDuration,
    onTimerEnd,
    handleResetTimer,
    setEmail,
    loadingResend,
  } = useTowFactorModalActions();

  const { isMatchTablet } = useMatchTablet();
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, updateErrorMessage] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const userLoginFormMethods = useForm({
    defaultValues: { email: '', password: '' },
    mode: 'all',
    resolver: yupResolver(schemaUserLogin),
  });

  const visiblePassword = () => setShowPassword((prevState) => !prevState);

  const handleValidSubmit = async (values) => {
    setLoading(true);
    updateErrorMessage('');
    setSubmitted(true);
    setEmail(values.email);
    try {
      const res = await dispatch(signInV3(values));
      if (!res?.data?.data?.token) {
        setTowFactorKey(res?.data?.data.key);
        setOpen(true);
        setLoading(false);
        setSubmitted(false);
      } else {
        setOpen(false);
        setLoading(false);
        const { from } = location.state || { from: { pathname: '/', search: '' } };
        if (window && window.location) {
          window.location.href = `${from.pathname}${from.search}`;
        } else {
          history.replace(from);
        }
        return true;
      }
    } catch (err) {
      setOpen(false);
      setTowFactorKey('');
      if (err?.response?.status === 401) {
        if (err?.response?.data?.message !== TOW_FACTOR_BLOCK_MESSAGE) {
          userLoginFormMethods.setError('password', {
            message: 'Your username or password is wrong. Please try again.',
          });
        }
      } else {
        updateErrorMessage(err?.response?.data?.message || 'An unexpected error occurred. Please try again later.');
      }
      setLoading(false);
      setSubmitted(false);
    }
  };

  return {
    handleValidSubmit,
    errorMessage,
    userLoginFormMethods,
    submitted,
    visiblePassword,
    showPassword,
    isMatchTablet,
    loading,
    towFactorKey,
    open,
    handleClose,
    setTowFactorKey,
    timerFinished,
    resetDuration,
    onTimerEnd,
    handleResetTimer,
    loadingResend,
  };
};
