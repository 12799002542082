const prefix = 'V2';
const prefixTO = 'TO';

// Menus
export const GET_MENUS_V2 = `GET_MENUS_${prefix}`;
export const SET_MENUS_V2 = `SET_MENUS_${prefix}`;
export const SET_MENUS_AVAILABLE_V2 = `SET_MENUS_AVAILABLE_${prefix}`;

// Link with provider
export const SET_LINK_PROVIDER_VISIBILITY = `SET_LINK_PROVIDER_VISIBILITY`;
export const SET_LINK_PROVIDER = `SET_LINK_PROVIDER`;
export const SET_CONNECTED_PROVIDERS = `SET_CONNECTED_PROVIDERS`;
export const SET_TARGET_CONNECTED_PROVIDERS = `SET_TARGET_CONNECTED_PROVIDERS`;
export const RESET_LINK_PROVIDER = `RESET_LINK_PROVIDER`;
export const SET_LINK_PROVIDER_LOADING = `SET_LINK_PROVIDER_LOADING`;
export const PROVIDERS_START_RESUME = `PROVIDERS_START_RESUME`;
export const SET_PROVIDER_DOORDASH_LOADING = `SET_PROVIDER_DOORDASH_LOADING`;
export const SET_PROVIDER_GRUBHUB_LOADING = `SET_PROVIDER_GRUBHUB_LOADING`;

// Menu categories
export const GET_MENU_CATEGORIES_V2 = `GET_MENU_CATEGORIES_${prefix}`;
export const SET_MENU_CATEGORIES_V2 = `SET_MENU_CATEGORIES_${prefix}`;

// Menu Modifiers
export const SET_MENU_MODIFIERS_ALL = `SET_MENU_MODIFIERS_ALL_${prefix}`;
export const SET_MENU_MODIFIERS_LOADING = `SET_MENU_MODIFIERS_LOADING_${prefix}`;
export const SET_CURRENT_MODIFIER = `SET_CURRENT_MODIFIER_${prefix}`;
export const SET_MENU_CURRENT_MODIFIER = `SET_MENU_CURRENT_MODIFIER${prefix}`;

// Menu category products
export const SET_MENU_PRODUCTS_ALL = `SET_MENU_PRODUCTS_ALL_${prefix}`;
export const SET_MENU_PRODUCTS_SIZE = `SET_MENU_PRODUCTS_SIZE`;
export const GET_CATEGORY_PRODUCTS_V2 = `GET_CATEGORY_PRODUCTS_${prefix}`;
export const SET_CATEGORY_PRODUCTS_V2 = `SET_CATEGORY_PRODUCTS_${prefix}`;
export const SET_FORM_PRODUCT = `SET_FORM_PRODUCT_${prefix}`;
export const SET_INGREDIENTS_V2 = `SET_INGREDIENTS_${prefix}`;
export const SET_CHANGE_PRICE_V2 = `SET_CHANGE_PRICE_${prefix}`;

// Orders
export const GET_ORDERS = `GET_ORDERS`;
export const SET_ORDERS = `SET_ORDERS`;
export const ORDERS_PUSH = `ORDERS_PUSH`;
export const ORDERS_HISTORY = `ORDERS_HISTORY`;
export const ORDERS_HISTORY_LOADING = `ORDERS_HISTORY_LOADING`;
export const ORDERS_EMPTY = `ORDERS_EMPTY`;
export const SET_ORDERS_PAGINATION = 'SET_ORDERS_PAGINATION';
export const SET_ORDERS_PARAMS = 'SET_ORDERS_PARAMS';
export const RESET_ORDERS_PAGINATION = 'RESET_ORDERS_PAGINATION';
export const UPDATE_MODIFIED_ORDERS = 'UPDATE_MODIFIED_ORDERS';
export const RESET_MODIFIED_ORDERS = 'RESET_MODIFIED_ORDERS';
export const SET_LAST_UPDATED_ORDER = 'SET_LAST_UPDATED_ORDER';
export const SET_HEADER_IS_TOUCHED = 'SET_HEADER_IS_TOUCHED';

export const SET_LAST_CANCELED_ORDER = 'SET_LAST_CANCELED_ORDER';
export const UPDATE_CANCELED_ORDERS = 'UPDATE_CANCELED_ORDERS';
export const RESET_CANCELED_ORDERS = 'RESET_CANCELED_ORDERS';
export const SET_ORDERS_REPORT = 'SET_ORDERS_REPORT';
export const SET_DISABLED_ACTIONS = 'SET_DISABLED_ACTIONS';

// Loading
export const SET_LOADING = 'SET_LOADING';
export const SET_LOADING_PRODUCT = 'SET_LOADING_PRODUCT';

// Pagination
export const SET_PAGINATION_V2 = `SET_PAGINATION_${prefix}`;

// MenuV2 params
export const SET_MENU_PARAMS_V2 = `SET_MENU_PARAMS_${prefix}`;

// Menu Socket categories
export const SET_MENU_CATEGORIES_V2_SOCKET_CREATE = `SET_MENU_CATEGORIES_${prefix}_SOCKET_CREATE`;
export const SET_MENU_CATEGORIES_V2_SOCKET_UPDATE = `SET_MENU_CATEGORIES_${prefix}_SOCKET_UPDATE`;
export const SET_MENU_CATEGORIES_V2_SOCKET_DELETE = `SET_MENU_CATEGORIES_${prefix}_SOCKET_DELETE`;
export const SET_MENU_CATEGORIES_V2_SOCKET_PUSH = `SET_MENU_CATEGORIES_${prefix}_SOCKET_PUSH`;
export const SET_MENU_CATEGORIES_V2_SOCKET_SORT = `SET_MENU_CATEGORIES_${prefix}_SOCKET_SORT`;
export const SET_MENU_CATEGORIES_V2_SOCKET_IMPORT = `SET_MENU_CATEGORIES_${prefix}_SOCKET_IMPORT`;

// Settings Events
export const ADD_USER = `ADD_USER`;
export const SET_EVENT_DEFAULT = `SET_EVENT_DEFAULT`;
export const SET_EVENT_ACTIONS = `SET_EVENT_ACTIONS`;
export const SET_EVENT_LIST = `SET_EVENT_LIST`;
export const UPDATE_EVENT_LIST = `UPDATE_EVENT_LIST`;
export const ADD_EVENT_LIST = `ADD_EVENT_LIST`;
export const DELETE_EVENT_LIST = `DELETE_EVENT_LIST`;
export const SET_EVENT_LIST_LOADING = `SET_EVENT_LIST_LOADING`;

//Settings Printers
export const GET_PRINTERS_LIST = `GET_PRINTERS_LIST`;
export const SET_PRINTERS_LIST = `SET_PRINTERS_LIST`;
export const UPDATE_PRINTERS_LIST = `UPDATE_PRINTERS_LIST`;
export const ADD_PRINTERS_LIST = `ADD_PRINTERS_LIST`;
export const DELETE_PRINTERS_LIST = `DELETE_PRINTERS_LIST`;
export const SET_PRINTERS_LIST_LOADING = `SET_PRINTERS_LIST_LOADING`;

//Multiple Businesses
export const SET_CURRENT_BUSINESSES = `SET_CURRENT_BUSINESSES`;
export const SET_CURRENT_BUSINESSES_DISABLE = `SET_CURRENT_BUSINESSES_DISABLE`;
export const SET_CURRENT_BUSINESSES_ENABLE = `SET_CURRENT_BUSINESSES_ENABLE`;
export const SET_TIMEZONE = `SET_TIMEZONE`;
export const SET_CUISINES = `SET_CUISINES`;
export const SET_BUSINESS_CLASSIFICATION = `SET_BUSINESS_CLASSIFICATION`;

// Reports
export const SALES_BY_LIST = `SALES_BY_LIST`;
export const REPORT_LIST = `REPORT_LIST`;
export const SET_REPORT_LIST = `SET_REPORT_LIST`;
export const SET_REPORT_HEADER_KEYS = `SET_REPORT_HEADER_KEYS`;
export const EMPTY_REPORT_LIST = `EMPTY_REPORT_LIST`;
export const SET_REPORT_LOADING = `SET_REPORT_LOADING`;
export const SET_REPORT_FILTERS = `SET_REPORT_FILTERS`;
export const SET_SELECTED_LOCATIONS = `SET_SELECTED_LOCATIONS`;
export const SET_SELECTED_BRANDS = `SET_SELECTED_BRANDS`;
export const GET_REPORT_FILTERS = `GET_REPORT_FILTERS`;
// export const ADD_REPORT_FILTERS = `ADD_REPORT_FILTERS`;
export const DELETE_REPORT_FILTERS = `DELETE_REPORT_FILTERS`;

// Availability
export const SET_AVAILABILITY_CUSTOM = `SET_AVAILABILITY_CUSTOM`;

// Upload Bulk
export const SET_CHECKED_PROVIDER = 'SET_CHECKED_PROVIDER';

// Coupons
export const SET_ACTIVE_COUPONS = 'SET_ACTIVE_COUPONS';
export const SET_EXPIRED_COUPONS = 'SET_EXPIRED_COUPONS';
export const SET_COUPON_LOADING = 'SET_COUPON_LOADING';

// Payment

export const SET_PAYMENT_NOTIFICATION = 'SET_PAYMENT_NOTIFICATION';

//New Business list Header
export const SET_ALL_BRAND = 'SET_ALL_BRAND';
export const SET_IS_LOADING = 'SET_IS_LOADING';
export const SET_RESET = 'SET_RESET';
export const SET_LOCATIONS_LIST = 'SET_LOCATIONS_LIST';

// Table Ordering
export const LAST_CREATED_EMPLOYEE_TO = `LAST_CREATED_EMPLOYEE_${prefixTO}`;
export const ADD_EMPLOYEE_TO = `ADD_EMPLOYEE_${prefixTO}`;
export const GET_EMPLOYEE_TO = `GET_EMPLOYEE_${prefixTO}`;
export const UPDATE_EMPLOYEE_TO = `UPDATE_EMPLOYEE_${prefixTO}`;
export const DELETE_EMPLOYEE_TO = `DELETE_EMPLOYEE_${prefixTO}`;
export const LAST_CREATED_TABLE_TO = `LAST_CREATED_TABLE_${prefixTO}`;
export const LAST_CREATED_SECTION_TO = `LAST_CREATED_SECTION_${prefixTO}`;
export const SET_LOADING_SETTINGS_TO = `SET_LOADING_SETTINGS_${prefixTO}`;
export const ADD_TABLE_TO = `ADD_TABLE_${prefixTO}`;
export const GET_TABLE_TO = `GET_TABLE_${prefixTO}`;
export const UPDATE_TABLE_TO = `UPDATE_TABLE_${prefixTO}`;
export const DELETE_TABLE_TO = `DELETE_TABLE_${prefixTO}`;
export const ADD_SECTION_TO = `ADD_SECTION_${prefixTO}`;
export const GET_SECTION_TO = `GET_SECTION_${prefixTO}`;
export const UPDATE_SECTION_TO = `UPDATE_SECTION_${prefixTO}`;
export const DELETE_SECTION_TO = `DELETE_SECTION_${prefixTO}`;
export const GET_SETTINGS_TO = `GET_SETTINGS_${prefixTO}`;
export const CREATE_BUSINESS_TO = `CREATE_BUSINESS_${prefixTO}`;
export const SET_LOADING_TO = `SET_LOADING_${prefixTO}`;
export const SET_IS_BEEPING = `SET_IS_BEEPING`;
export const SET_CURRENT_BRAND = 'SET_CURRENT_BRAND';
export const COLLECT_BUSINESS_IDS_FOR_REPORT = 'COLLECT_BUSINESS_IDS_FOR_REPORT';
export const COLLECT_BUSINESS_IDS_FOR_HISTORY = 'COLLECT_BUSINESS_IDS_FOR_HISTORY';
export const COLLECT_BRAND_BUSINESS_IDS_FOR_REPORT = 'COLLECT_BRAND_BUSINESS_IDS_FOR_REPORT';
export const COLLECT_BRAND_BUSINESS_IDS_FOR_HISTORY = 'COLLECT_BRAND_BUSINESS_IDS_FOR_HISTORY';
export const IS_LOCATION_UNSELECT = 'IS_LOCATION_UNSELECT';
export const SET_CURRENT_SELECT_BRANDS = 'SET_CURRENT_SELECT_BRANDS';
export const SET_CURRENT_SELECT_LOCATIONS = 'SET_CURRENT_SELECT_LOCATIONSv';
export const CLICK_APPLY_FILTER = 'CLICK_APPLY_FILTER';
export const SET_LOCATION_FOR_BRANDS = 'SET_LOCATION_FOR_BRANDS';
export const SET_ALL_SELECTED_BRANDS = 'SET_ALL_SELECTED_BRANDS';
export const SET_ALL_SELECTED_LOCATION = 'SET_ALL_SELECTED_LOCATION';

// Providers
export const SET_PROVIDER_CONFIG = 'SET_PROVIDER_CONFIG';
export const SET_POS_DATA = 'SET_POS_DATA';
export const SET_SHOW_ACTIVE_BUSINESS = 'SET_SHOW_ACTIVE_BUSINESS';
export const ALL_BRANDS_WITHOUT_FILTER = 'ALL_BRANDS_WITHOUT_FILTER';
export const ALL_BRANDS_OF_FILTER = 'ALL_BRANDS_OF_FILTER';
export const SET_CONNECTION_LOADING = `SET_CONNECTION_LOADING`;

// Business Group
export const SET_ALL_BUSINESS_GROUPS = 'SET_ALL_BUSINESS_GROUPS';
export const ALL_BRANDS_DEPENDS_ON_BUSINESS_GROUP = 'ALL_BRANDS_DEPENDS_ON_BUSINESS_GROUP';
export const ALL_LOCATIONS_DEPENDS_ON_BUSINESS_GROUP = 'ALL_LOCATIONS_DEPENDS_ON_BUSINESS_GROUP';
