import React, { useState, useEffect, useMemo, useCallback } from 'react';
import Box from '@mui/material/Box';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, FormProvider, useFieldArray } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { v4 as uuidv4 } from 'uuid';
import { set } from 'lodash';
import { DialogTitle, Dialog, Button, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { updateMenuProduct, deleteMenuProduct, addMenuProduct } from 'redux/actions/Menu/menu';
import { getProductsAll, getProductById, getCategoryProductsV2 } from 'redux/actions/Menu/products';
import { groupModifier, deleteModifier, getModifiersAll } from 'redux/actions/Menu/modifiers';
import { TabsList, StyledTab } from './styled';
import ProductFormBasic from 'components/MenuNew/FormView/ProductFormBasic';
import ProductFormDetails from 'components/MenuNew/FormView/ProductFormDetails';
import FormHeader from 'components/MenuNew/FormView/FormHeader';
import Preloader from 'components/Preloader';
import { confirm } from 'components/MenuNew/ConfirmModal';
import UpdateAvailability from './UpdateAvailability/UpdateAvailability';
import { SET_CHANGE_PRICE_V2, SET_FORM_PRODUCT, SET_LOADING } from 'redux/actions/types';
import Private from 'components/Private';
import { SOLD_OUT_STATUS } from 'constants/enumSoldOut';
import { MENU_EDIT } from 'constants/permissions';
import useQuery from 'hooks/useQuery';
import usePermission from 'hooks/permission';
import ModifierGroupForm from '../NewModifierGroup/index';
import { CONTEXT_TYPES } from 'constants/menu';
import ModifierOptions from './ModifierOption/index';
import FormFooter from './FormFooter';
import { getMenusV2 } from 'redux/actions/Menu/menuV2';
import { priceTransform } from 'helpers/formPrice';
import ConfirmPromptModal from 'components/ConfirmPromptModal/ConfirmPromptModal';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { getNextIDsAfterConfirmPopup } from 'utils/menu';
import useProviderConfig from 'hooks/useProviderConfig';
import ConfirmProductActions from 'components/ConfirmProductActions';
import {
  ModalOpenStatusEnum,
  SET_MODAL_ACTIONS,
  SET_PRODUCT_EVENTS,
  SET_PRODUCT_EVENTS_NEXT_LOCATION,
} from 'components/ConfirmProductActions/constants';
import { GREATER_MSG, LESS_MSG } from 'constants/products';
import DefaultSelections from 'components/MenuNew/FormView/DefaultSelections';

function BasePriceConfirmDialog(props) {
  const { t } = useTranslation();

  const { onClose, setConfirmed, open } = props;

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>Hello</DialogTitle>
      <Button onClick={() => setConfirmed(true)}>Yes</Button>
      <Button onClick={() => setConfirmed(false)}>{t('orders.cancel')}</Button>
    </Dialog>
  );
}

function TabPanel(props) {
  const { value, index, content } = props;

  return (
    <div role="tabpanel" hidden={value !== index}>
      {value === index && <Box sx={{ py: 3 }}>{content}</Box>}
    </div>
  );
}

const initialData = {
  name: '',
  price: '',
  refs: [],
  websitePrice: '',
  image: {
    url: '',
    imageId: '',
  },
  description: '',
  carbs: '',
  sort: 0,
  tax: '',
  calories: '',
  proteins: '',
  priceOverride: [
    { context_type: CONTEXT_TYPES.provider, context_value: 'main', price: 0, pickupPrice: '', locked: true },
  ],
  modifiers: [],
  isActive: true,
  onlyUsedAsModifer: false,
  sizeTypeModifiers: [],
  isOnePrice: true,
  connectedProviders: { main: true },
  category: '',
  classifications: [],
  ingredients: [],
  soldOut: {
    status: SOLD_OUT_STATUS.AVAILABILITY,
    soldOutDate: '',
  },
  onItsOwn: true,
  isPopular: false,

  popularStartDate: new Date().toISOString(),
  popularEndDate: new Date().toISOString(),
  isPermanentlyPopular: false,
  preselectOptions: {
    defaultSelection: false,
    options: {},
  },
};

const initialModifierData = {
  min: '',
  max: '',
  free: '',
  name: '',
  title: '',
  description: '',
  list: [
    {
      name: '',
      price: 0,
      priceOverride: [],
      optionSort: 0,
      pickupPrice: null,
      locked: true,
    },
  ],
  isActive: true,
  internalName: '',
  isOnePrice: true,
};

const productFormInfo = (providers, t = () => {}, isEdit) => {
  return yup.object().shape({
    name: yup.string().required('field_is_required_'),
    isPopular: yup.boolean().nullable(),
    price: yup.lazy((value) =>
      value === ''
        ? yup.string().required('field_is_required_')
        : yup
            .number()
            .min(0, 'price_must_equal_to_0_')
            .transform((value) => (isNaN(value) ? null : value))
            .typeError('field_is_required_')
            .required('field_is_required_')
            .test('sizeTypeModifiers', 'price_$375.00_for_ubereats_', (price, option) => {
              let tested = true;
              if (option.parent.priceOverride) {
                option.parent.priceOverride.map((item) => {
                  if (item.context_value === providers.ubereats.srv) {
                    if (option.parent.isOnePrice) {
                      if (option.parent?.connectedProviders[providers.ubereats.srv] && price > 374 && !item.price) {
                        tested = false;
                      } else {
                        tested = true;
                      }
                    }
                  }
                });
                return tested;
              }
            })
    ),
    tax: yup
      .number()
      .min(null, 'price_must_equal_to_0_')
      .transform((value) => (isNaN(value) ? null : value))
      .nullable(),
    description: yup.string().nullable(),
    priceOverride: yup.array(
      yup.object().shape({
        price: yup.lazy((value) =>
          value === ''
            ? yup.string().required('field_is_required_')
            : yup
                .number()
                .min(0, 'price_must_equal_to_0_')
                .transform((value) => (isNaN(value) ? null : value))
                .typeError('field_is_required_')
                .required('field_is_required_')
                .test('sizeTypeModifiers', 'price_$375.00_for_ubereats_', (price, option) => {
                  const params = new URLSearchParams(document.location.search);
                  const pid = params.get('pid');
                  const isSizeType = option.from.filter((item) => item.value?.isOnePrice)?.find((item) => item);
                  const connectedProviders = option.from
                    .map((item) => item.value)
                    .find((item) => item._id === pid)?.connectedProviders;
                  if (!connectedProviders) {
                    return true;
                  }
                  if (
                    !isSizeType?.value.isOnePrice &&
                    option.parent.context_value === providers.ubereats.srv &&
                    connectedProviders[providers.ubereats.srv]
                  ) {
                    return true;
                  } else {
                    return !(
                      option.parent.context_value === providers.ubereats.srv &&
                      connectedProviders[providers.ubereats.srv] &&
                      price > 374
                    );
                  }
                })
        ),
        pickupPrice: yup.lazy((value) => {
          return yup
            .number()
            .min(0, 'price_must_equal_to_0_')
            .transform((value) => (isNaN(value) ? null : value))
            .nullable()
            .test('sizeTypeModifiers', 'price_$375.00_for_ubereats_', (pickupPrice, option) => {
              const params = new URLSearchParams(document.location.search);
              const pid = params.get('pid');
              const isSizeType = option.from.filter((item) => item.value?.isOnePrice)?.find((item) => item);
              const connectedProviders = option.from
                .map((item) => item.value)
                .find((item) => item._id === pid)?.connectedProviders;
              if (!connectedProviders) {
                return true;
              }
              if (
                !isSizeType?.value.isOnePrice &&
                option.parent.context_value === providers.ubereats.srv &&
                connectedProviders[providers.ubereats.srv]
              ) {
                return true;
              } else {
                return option.parent.context_value === providers.ubereats.srv &&
                  connectedProviders[providers.ubereats.srv] &&
                  pickupPrice > 374
                  ? false
                  : true;
              }
            });
        }),
      })
    ),
    sizeTypeModifiers: yup.array(
      yup.object().shape({
        list: yup.array(
          yup.object().shape({
            name: yup.string().required('field_is_required_'),
            optionSort: yup.number().required('field_is_required_'),
            price: yup.lazy((value) =>
              value === ''
                ? yup.string().required('field_is_required_')
                : yup
                    .number()
                    .min(0, 'price_must_equal_to_0_')
                    .transform((value) => (isNaN(value) ? null : value))
                    .typeError('field_is_required_')
                    .required('field_is_required_')
                    .test('list', 'price_$375.00_for_ubereats_', (price, option) => {
                      const params = new URLSearchParams(document.location.search);
                      const pid = params.get('pid');
                      if (option.from && value && typeof value === 'number') {
                        const connectedProviders = option.from
                          .map((item) => item.value)
                          .find((item) => item._id === pid)?.connectedProviders;

                        if (!connectedProviders) {
                          return true;
                        }
                        let tested = true;
                        if (option.parent.priceOverride) {
                          option.parent.priceOverride.map((item) => {
                            if (item.context_value === providers.ubereats.srv) {
                              if (connectedProviders[providers.ubereats.srv] && price > 374 && !item.price) {
                                tested = false;
                              } else {
                                tested = true;
                              }
                            }
                          });
                          return tested;
                        }
                      } else {
                        return true;
                      }
                    })
            ),
            priceOverride: yup.array(
              yup.object().shape({
                price: yup.lazy((value) =>
                  yup
                    .number()
                    .transform((value) => (isNaN(value) ? null : value))
                    .nullable()
                    .test('sizeTypeModifiers', 'price_$375.00_for_ubereats_', (price, option) => {
                      const params = new URLSearchParams(document.location.search);
                      const pid = params.get('pid');
                      if (
                        option.from &&
                        value &&
                        typeof value === 'number' &&
                        option.parent.context_value === providers.ubereats.srv
                      ) {
                        const connectedProviders = option?.from
                          ?.map((item) => item.value)
                          ?.find((item) => item._id === pid)?.connectedProviders;

                        if (!connectedProviders) {
                          return true;
                        }

                        return !(
                          connectedProviders[providers.ubereats.srv] &&
                          option.parent.context_value === providers.ubereats.srv &&
                          price > 374
                        );
                      } else {
                        return true;
                      }
                    })
                ),
                pickupPrice: yup.lazy((value, option) => {
                  return yup
                    .number()
                    .transform((value) => (isNaN(value) ? null : value))
                    .nullable()
                    .test('sizeTypeModifiers', 'price_$375.00_for_ubereats_', (pickupPrice, option) => {
                      const params = new URLSearchParams(document.location.search);
                      const pid = params.get('pid');
                      if (
                        option.from &&
                        value &&
                        typeof value === 'number' &&
                        option.parent.context_value === providers.ubereats.srv
                      ) {
                        const connectedProviders = option?.from
                          ?.map((item) => item.value)
                          ?.find((item) => item._id === pid)?.connectedProviders;

                        if (!connectedProviders) {
                          return true;
                        }

                        return connectedProviders[providers.ubereats.srv] &&
                          option.parent.context_value === providers.ubereats.srv &&
                          pickupPrice > 374
                          ? false
                          : true;
                      } else {
                        return true;
                      }
                    });
                }),
              })
            ),
          })
        ),
      })
    ),
    proteins: yup
      .number()
      .min(0, 'proteins_or_equal to_0_')
      .transform((value) => (isNaN(value) ? null : value))
      .nullable(),
    carbs: yup
      .number()
      .min(0, 'carbs_must_equal_to_0_')
      .transform((value) => (isNaN(value) ? null : value))
      .nullable(),
    calories: yup
      .number()
      .min(0, 'calories_must_equal_to_0')
      .transform((value) => (isNaN(value) ? null : value))
      .nullable(),
    minAllowedQuantity: yup
      .number()
      .max(10000, 'menu.max_number')
      .positive('menu.min_allowed_quantity')
      .transform((value) => (isNaN(value) ? null : value))
      .nullable()
      .test('min_allowed_quantity', function (val) {
        const { maxAllowedQuantity } = this.parent || {};
        if (val == null) {
          return true;
        }
        if (maxAllowedQuantity && val > maxAllowedQuantity) {
          return this.createError({
            message: `${t(GREATER_MSG)} ${maxAllowedQuantity}`,
          });
        }
        return true;
      }),
    maxAllowedQuantity: yup
      .number()
      .max(10000, 'menu.max_number')
      .positive('menu.max_allowed_quantity')
      .transform((value) => (isNaN(value) ? null : value))
      .nullable()
      .test('max_allowed_quantity', function (val) {
        const { minAllowedQuantity } = this.parent || {};
        if (val == null) {
          return true;
        }
        if (minAllowedQuantity && val < minAllowedQuantity) {
          return this.createError({
            message: `${t(LESS_MSG)} ${minAllowedQuantity}`,
          });
        }
        return true;
      }),
    minTotalNumberOfModifierItems: yup
      .number()
      .max(10000, 'menu.max_number')
      .positive('menu.min_total_number_modifier_items')
      .transform((value) => (isNaN(value) ? null : value))
      .nullable()
      .test('min_total_number_modifier_items', function (val) {
        const { maxTotalNumberOfModifierItems } = this.parent || {};
        if (val == null) {
          return true;
        }
        if (maxTotalNumberOfModifierItems && val > maxTotalNumberOfModifierItems) {
          return this.createError({
            message: `${t(GREATER_MSG)} ${maxTotalNumberOfModifierItems}`,
          });
        }
        return true;
      }),
    maxTotalNumberOfModifierItems: yup
      .number()
      .max(10000, 'menu.max_number')
      .positive('menu.max_total_number_modifier_items')
      .transform((value) => (isNaN(value) ? null : value))
      .nullable()
      .test('max_total_number_modifier_items', function (val) {
        const { minTotalNumberOfModifierItems } = this.parent || {};
        if (val == null) {
          return true;
        }
        if (minTotalNumberOfModifierItems && val < minTotalNumberOfModifierItems) {
          return this.createError({
            message: `${t(LESS_MSG)} ${minTotalNumberOfModifierItems}`,
          });
        }
        return true;
      }),
    minTotalNumberOfModifierGroups: yup
      .number()
      .max(10000, 'menu.max_number')
      .positive('menu.min_total_number_modifier_groups')
      .transform((value) => (isNaN(value) ? null : value))
      .nullable()
      .test('min_total_number_modifier_groups', function (val) {
        const { maxTotalNumberOfModifierItems } = this.parent || {};
        if (val == null) {
          return true;
        }
        if (maxTotalNumberOfModifierItems && val > maxTotalNumberOfModifierItems) {
          return this.createError({
            message: `${t(GREATER_MSG)} ${maxTotalNumberOfModifierItems}`,
          });
        }
        return true;
      }),
    maxTotalNumberOfModifierGroups: yup
      .number()
      .max(10000, 'menu.max_number')
      .positive('menu.max_total_number_modifier_groups')
      .transform((value) => (isNaN(value) ? null : value))
      .nullable()
      .test('max_total_number_modifier_groups', function (val) {
        const { minTotalNumberOfModifierGroups } = this.parent || {};
        if (val == null) {
          return true;
        }
        if (minTotalNumberOfModifierGroups && val < minTotalNumberOfModifierGroups) {
          return this.createError({
            message: `${t(LESS_MSG)} ${minTotalNumberOfModifierGroups}`,
          });
        }
        return true;
      }),
    popularEndDate: yup.lazy((value, obj) => {
      if (!obj.parent?.isPermanentlyPopular && obj.parent.isPopular) {
        if (!value?.length) {
          return yup.string().required('field_is_required_');
        } else {
          if (new Date(value) == 'Invalid Date') {
            return yup.date().typeError('the_date_format_is_invalid_');
          } else if (
            new Date(obj.parent?.popularStartDate).getTime() > new Date(obj.parent?.popularEndDate).getTime() &&
            obj.parent?.popularEndDate
          ) {
            return yup.date().typeError('the_start_dates_');
          } else {
            return yup.string().nullable();
          }
        }
      } else {
        return yup.string().nullable();
      }
    }),
    popularStartDate: yup.lazy((value, obj) => {
      if (!obj.parent?.isPermanentlyPopular && obj.parent.isPopular) {
        if (!value?.length) {
          return yup.string().required('field_is_required_');
        } else {
          if (new Date(value) == 'Invalid Date') {
            return yup.date().typeError('the_date_format_is_invalid_');
          } else if (
            new Date(obj.parent?.popularStartDate).getTime() > new Date(obj.parent?.popularEndDate).getTime() &&
            obj.parent?.popularStartDate
          ) {
            return yup.date().typeError('the_end_date_cannot_start_date_');
          } else {
            return yup.string().nullable();
          }
        }
      } else {
        return yup.string().nullable();
      }
    }),
  });
};

const ProductForm = () => {
  const { t } = useTranslation();
  const { providers } = useProviderConfig();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const query = useQuery();
  const mode = query.get('mode');
  const isEdit = mode === 'edit';
  const searchParams = new URLSearchParams(location.search);
  const checkPermission = usePermission();
  const isInOverview = history.location.pathname.includes('overview');
  const [tabValue, setTabValue] = useState(0);
  const [loading, setLoading] = useState(false);
  const [loadingDuplicate, setLoadingDuplicate] = useState(false);
  const [basePriceConfirmDialogOpen, handleBasePriceConfirmDialogOpen] = useState(false);
  const [currentProduct, setCurrentProduct] = useState(null);
  const [productIsModOption, handleProductIsModOption] = useState(false);
  const [currentCategory, setCurrentCategory] = useState(null);
  const [currentModifier, setCurrentModifier] = useState(null);
  const [currentModifierName, setCurrentModifierName] = useState(currentModifier?.name);
  const { menu, formProduct, changeBasePriceData, menuAvailable } = useSelector(({ menu }) => menu);
  const userData = useSelector(({ user: { data } }) => ({
    data,
  }));

  dayjs.extend(utc);
  dayjs.extend(timezone);
  const { currentBusiness } = useSelector(({ business }) => business);
  // const [prompt, setDirty, setPristine] = useModifyForm();
  const [isDirty, setIsDirty] = useState(false);
  const [modifierFormState, setModifierFormState] = useState(initialModifierData);
  const { productEvents } = useSelector((store) => store);

  const readOnly = useMemo(() => {
    return !checkPermission(MENU_EDIT);
  }, [checkPermission, menu]);

  // const priceWatcher = useWatch('price');
  const productFormMethods = useForm({
    defaultValues: initialData,
    mode: 'all',
    resolver: yupResolver(productFormInfo(providers, t, isEdit)),
  });

  const { reset, getValues, formState, setValue, control, trigger, register } = productFormMethods;

  const sizeTypeModifierMethods = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: 'sizeTypeModifiers', // unique name for your Field Array
  });

  const productPriceOverride = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: 'priceOverride', // unique name for your Field Array
  });

  const sizeTypeModifierListMethods = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: 'sizeTypeModifiers[0].list', // unique name for your Field Array
  });

  const deletedOptionIds = useFieldArray({
    control,
    name: 'sizeTypeModifiers[0].deletedOptionIds',
  });

  const modifierGroupMethods = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: 'modifiers', // unique name for your Field Array
  });

  const [title, setTitle] = useState(getValues().name);
  // const [sizeTypeModifier, handleSizeTypeModifier] = useState(getValues().sizeTypeModifier);

  const productId = query.get('pid');
  const cid = query.get('cid');
  const modifierId = query.get('mid');
  const entityType = query.get('entityType');
  const showModifier = modifierId && entityType === 'modifier';
  const [isOpen, setIsOpen] = useState(false);
  const [productData, setProductData] = useState({});
  const [getImageValue, setImageGetvalue] = useState('');
  const validateImage = getValues()?.image?.url === currentProduct?.image?.url;
  const providerPriceOverride = [];
  const [disable, setDisable] = useState(true);
  const [formErrors, updateFormErrors] = useState({
    name: '',
    description: '',
    list: [{ name: '', price: '', priceOverride: [{ name: '', price: null, pickupPrice: null }] }],
  });
  const [isChanged, setIsChanged] = useState(false);
  const [errorData, setErrorData] = useState();

  const handleClickOpen = () => {
    setIsOpen(!isOpen);
  };

  const handleCloseBasePriceConfirmModal = (value) => {
    handleBasePriceConfirmDialogOpen(value);
  };

  const getProduct = () => {
    if (mode === 'edit') {
      reset({
        image: {
          url: '',
          imageId: '',
        },
      });
      setLoading(true);
      dispatch(getProductById(productId, cid))
        .then((res) => {
          if (res.category) {
            query.set('cid', res.category);
          } else {
            query.delete(cid);
          }
          if (!isInOverview) {
            history.push({ pathname: history.location.pathname, search: query.toString() });
          }
          dispatch({ type: SET_FORM_PRODUCT, payload: res });
          reset(res, { keepDirtyValues: false, keepDirty: false });
          setCurrentProduct(res);
        })
        .finally(() => {
          setTabValue(0);
          setLoading(false);
        });
    } else {
      reset(initialData);
    }
  };

  useEffect(() => {
    getProduct();
  }, [productId]);

  useEffect(() => {
    if (history.location.state?.tabValue) {
      setTabValue(history.location.state?.tabValue);
    }
  }, [history.location]);

  useEffect(() => {
    if (formProduct) {
      formProduct.ingredients =
        formProduct?.ingredients?.map((item) => {
          if (item?.name) {
            return item?.name;
          } else {
            return item;
          }
        }) || [];
    }
    // if (isInOverview) {
    if (
      productEvents.modalStatus === ModalOpenStatusEnum.opened ||
      productEvents.modalStatus === ModalOpenStatusEnum.cancel
    ) {
      if (isEdit) {
        reset(formProduct, { isDirty: true });
      }
    } else {
      if (isEdit) {
        reset(formProduct, { keepDirtyValues: false, keepDirty: false });
      }
    }
    // }
    setCurrentProduct(formProduct);
  }, [formProduct?._id, !currentProduct]);

  // useEffect(() => {
  //   if (!getValues().soldOut && mode === 'edit') {
  //     reset(currentProduct);
  //   }
  // }, [availableValue]);

  useEffect(() => {
    history.push({ pathname: history.location.pathname, search: history.location.search, state: {} });
  }, []);

  useEffect(() => {
    const categoryData = menu?.categories?.find((item) => item?._id === cid);
    if (isInOverview && categoryData) {
      Object.keys(categoryData.connectedProviders).map((modItem) => {
        if (categoryData.connectedProviders[modItem]) {
          const findPrice = getValues()?.priceOverride?.find((pr) => pr?.context_value === modItem)?.price;
          const findPickupPrice = getValues()?.priceOverride?.find((pr) => pr?.context_value === modItem)?.pickupPrice;
          const findLocked = getValues()?.priceOverride?.find((pr) => pr?.context_value === modItem);
          if (!isEdit) {
            providerPriceOverride.push({
              context_type: CONTEXT_TYPES.provider,
              context_value: modItem,
              name: modItem,
              price: findPrice && findPrice !== 0 ? findPrice : 0,
              pickupPrice: findPickupPrice && findPickupPrice !== 0 ? findPickupPrice : 0,
              locked: findLocked?.hasOwnProperty('locked') ? findLocked.locked : true,
            });
            setValue('priceOverride', providerPriceOverride, { shouldDirty: false, keepDefaultValues: false });
          }
        }
      });
      // productPriceOverride.replace(providerPriceOverride);
      setCurrentCategory(categoryData);
      if (!modifierId) {
        if (currentProduct && mode !== 'create') {
          if (getValues()._id === productId || currentProduct?._id === productId) {
            reset(getValues()?._id ? getValues() : currentProduct, {
              isDirty:
                productEvents.modalStatus === ModalOpenStatusEnum.cancel ||
                productEvents.modalStatus === ModalOpenStatusEnum.opened,
              keepDefaultValues:
                productEvents.modalStatus === ModalOpenStatusEnum.cancel ||
                productEvents.modalStatus === ModalOpenStatusEnum.opened ||
                (productEvents.modalStatus !== ModalOpenStatusEnum.confirm && history.location.state?.create) ||
                (!productEvents.eventData?.length && history.location.state?.create),
            });
          }
        } else {
          if (isInOverview) {
            reset(
              { ...initialData, category: cid, priceOverride: providerPriceOverride },
              { keepDirtyValues: false, keepDirty: false }
            );
          } else {
            reset(
              { ...initialData, priceOverride: providerPriceOverride },
              { keepDirtyValues: false, keepDirty: false }
            );
          }
        }
      }
    }
  }, [mode, menu.categories, menu.productsAll, menu.connectedProviders, menu.bulkInfo, productId, cid]);

  useEffect(() => {
    if (mode !== 'edit' && !modifierId) {
      return setTabValue(0);
    }
    return setTabValue(tabValue);
  }, [productId, mode, cid, tabValue]);

  useEffect(() => {
    if (currentProduct?.isOnePrice) {
      dispatch(getModifiersAll(currentProduct?.isOnePrice));
    }
  }, [currentProduct?.isOnePrice]);

  useEffect(() => {
    if (sizeTypeModifierListMethods.fields.length === 1) {
      reset(
        {
          ...getValues(),
          isOnePrice: true,
          price: getValues('price') || '',
          pickupPrice: getValues('pickupPrice') || null,
          locked: getValues('locked') || true,
          sizeTypeModifiers: [],
        },
        { keepDirtyValues: true, keepDirty: true }
      );
    } else {
      setValue('price', getValues('price') || 0);
    }
  }, [sizeTypeModifierListMethods.fields]);

  useEffect(() => {
    const defaultBusiness = userData.data?.business;
    if (defaultBusiness?._id !== currentBusiness?._id && Object.keys(formState.touchedFields).length) {
      handleSaveData();
    }
  }, [currentBusiness]);

  const routerGoBack = (cid) => {
    if (isInOverview) {
      history.push('/menu/overview');
    } else {
      history.push('/menu/items');
    }
    // // Example: Change the query params to ?name=JohnDoe
    // const newSearchParams = new URLSearchParams();
    // newSearchParams.set('cid', cid);
    // history.push({
    //   search: newSearchParams.toString(),
    // });
  };

  const handleValidSubmit = useCallback(
    async (values, e, change = false, newLocation = null) => {
      if (formProduct.modifiers && JSON.stringify(values.modifiers) !== JSON.stringify(formProduct.modifiers)) {
        const newData = getNextIDsAfterConfirmPopup(newLocation);
        handleSaveModifierGroup(history.location);
        if (newData?.newProductId) {
          dispatch(getProductById(newData?.newProductId, newData?.newCatId)).then((res) => {
            dispatch({ type: SET_FORM_PRODUCT, payload: res });
            reset(res);
          });
        }
      }
      if (e) {
        e.preventDefault();
      }

      const isError =
        Object.keys(productFormMethods.formState.errors)?.length || errorData
          ? Object.keys(errorData)?.length
          : !!errorData;

      if (isError) {
        Object.keys(errorData).forEach((key) => {
          productFormMethods.setError(key, { message: errorData[key].message });
        });
        return;
      }
      if (errorData && !Object.keys(errorData)?.length) {
        Object.keys(errorData).forEach((key) => {
          productFormMethods.clearErrors(key);
        });
      }

      if (values.popularStartDate || values.popularEndDate) {
        values.popularStartDate = dayjs(values.popularStartDate)
          .tz(currentBusiness.timezone?.timeZoneId, false)
          .set('date', dayjs(values.popularStartDate).get('date'))
          .set('month', dayjs(values.popularStartDate).get('month'))
          .set('year', dayjs(values.popularStartDate).get('year'))
          .startOf('day')
          .utc();
        // .format('YYYY-MM-DDTHH:mm:ss');
        values.popularEndDate = dayjs(values.popularEndDate)
          .tz(currentBusiness.timezone?.timeZoneId, false)
          .set('date', dayjs(values.popularEndDate).get('date'))
          .set('month', dayjs(values.popularEndDate).get('month'))
          .set('year', dayjs(values.popularEndDate).get('year'))
          .endOf('day')
          .utc();
        // .utc();
        // .format('YYYY-MM-DDTHH:mm:ss');
      }
      // handleSaveModifierGroup();
      if (values.sizeTypeModifiers[0]) {
        values.sizeTypeModifiers[0].list.forEach((item, index) => (item.optionSort = index));
      }

      values.ingredients = values.ingredients?.map((item) => {
        if (!item?._id) {
          return { name: item };
        } else {
          return { name: item?.name };
        }
      });

      //Setting SortOption value as ordered in array
      if (isEdit) {
        dispatch({ type: SET_LOADING, payload: true });
        setLoading(true);
        return dispatch(
          updateMenuProduct(productId, { category: values.category || currentCategory?._id, ...values }, cid)
        )
          .then((res) => {
            // Update or add a parameter
            if (cid !== values.category) {
              searchParams.set('cid', values.category);
              const newQueryString = '?' + searchParams.toString();
              history.replace({
                pathname: location.pathname,
                search: newQueryString,
              });
            }

            if (
              res.data?._id &&
              !res.events?.length &&
              (productEvents.modalStatus !== ModalOpenStatusEnum.cancel ||
                productEvents.modalStatus !== ModalOpenStatusEnum.opened)
            ) {
              dispatch({ type: SET_MODAL_ACTIONS, payload: ModalOpenStatusEnum.closed });
              setCurrentProduct(res?.data);
              reset(res?.data, { keepDirtyValues: false, keepDirty: false });
            }

            if (res.events?.length) {
              localStorage.setItem('SET_MODAL_ACTIONS', ModalOpenStatusEnum.opened);
              setLoading(false);
              dispatch({ type: SET_LOADING, payload: false });
            }

            if (cid !== values.category) {
              routerGoBack(res?.data.category);
            }
          })
          .finally(() => {
            setImageGetvalue('');
            setLoading(false);
            dispatch({
              type: SET_CHANGE_PRICE_V2,
              payload: {
                changeBasePrice: false,
                title: '',
                msg: '',
                msg2: '',
                price: 0,
                name: '',
                oldPrice: 0,
                isConfirm: false,
              },
            });
            dispatch({ type: SET_LOADING, payload: false });
          });
      } else {
        dispatch({ type: SET_LOADING, payload: true });
        setLoading(true);
        setProductData(values);
        if (!values?.name) {
          values = productData;
          values['checkZeroPrice'] = false;
        }
        return dispatch(addMenuProduct({ ...values }))
          .then((res) => {
            if (res?.data || values.hasOwnProperty('checkZeroPrice') || (!res?.data && res?._id)) {
              history.push({ pathname: `/menu/overview`, state: 'skip' });
              dispatch({ type: SET_MODAL_ACTIONS, payload: ModalOpenStatusEnum.closed });
              setCurrentProduct(res);
              reset(res);
            } else {
              dispatch({
                type: SET_PRODUCT_EVENTS_NEXT_LOCATION,
                payload: `/menu/overview`,
              });
            }
          })
          .finally(() => {
            setImageGetvalue('');
            setLoading(false);
            dispatch({
              type: SET_CHANGE_PRICE_V2,
              payload: {
                changeBasePrice: false,
                title: '',
                msg: '',
                msg2: '',
                price: 0,
                name: '',
                oldPrice: 0,
                isConfirm: false,
              },
            });
            dispatch({ type: SET_LOADING, payload: false });
          });
      }
    },
    [
      productFormMethods,
      errorData,
      isEdit,
      deletedOptionIds.fields,
      dispatch,
      productId,
      currentCategory?._id,
      reset,
      history,
    ]
  );

  const changeProductTab = async (_, tab) => {
    const result = await trigger(['name', 'description', 'price', 'sizeTypeModifiers[0]?.list']);
    if (!result) return;
    const fieldsEdited = (formState.isDirty && Object.keys(formState.dirtyFields)?.length) || !validateImage;
    const fieldsTouched = !formState.touchedFields.hasOwnProperty('soldOut') && formState.touchedFields;
    // check your dev console, it's a Set
    if (
      (tab != tabValue && fieldsEdited && fieldsTouched && formState.isDirty) ||
      (tab != tabValue && !validateImage && fieldsEdited && fieldsTouched) ||
      (tab != tabValue && fieldsTouched && formState.isDirty) ||
      (tab != tabValue && fieldsEdited && formState.isDirty)
    ) {
      const confirmed = await confirm({
        hideCloseIcon: false,
        hideDiscardBtn: false,
        onClose: () => {},
      });
      if (confirmed && !Object.keys(formState.errors).length) {
        if (tabValue === 1 && confirmed !== 'discard') {
          handleSaveModifierGroup(history.location);
          setTabValue(tab);
          dispatch({
            type: SET_PRODUCT_EVENTS_NEXT_LOCATION,
            payload: `${history.location.pathname}${history.location.search}`,
          });
        } else if (confirmed === 'discard' && !Object.keys(formState.errors).length) {
          setTabValue(tab);
          reset(formProduct);
          if (mode === 'create') {
            history.push('/menu/overview');
          }
        } else {
          handleValidSubmit(getValues(), _).then((res) => {
            setTabValue(tab);
            reset(res);
            setImageGetvalue('');
            dispatch({
              type: SET_PRODUCT_EVENTS_NEXT_LOCATION,
              payload: `${history.location.pathname}${history.location.search}`,
            });
          });
        }
      } else if (confirmed === 'discard') {
        setTabValue(tab);
        productFormMethods.clearErrors();
        updateFormErrors({});
        reset(formProduct);
        if (mode === 'create') {
          history.push('/menu/overview');
        }
      } else {
        setTabValue(tabValue);
        if (errorData) {
          productFormMethods.setError({ ...errorData });
          setErrorData({ ...errorData });
        }
        reset(getValues(), { keepDirty: true });
      }
    } else {
      setTabValue(tab);
    }
  };

  const changeIsOnePrice = (val = false) => {
    const initialSizeTypeModifier = [
      { id: uuidv4(), name: '', priceOverride: [], price: 0, pickupPrice: 0, optionSort: 0 },
      { id: uuidv4(), name: '', priceOverride: [], price: 0, pickupPrice: 0, optionSort: 1 },
    ];
    console.log(initialSizeTypeModifier, '>initialSizeTypeModifier');
    setValue('isOnePrice', val, { shouldValidate: true });
    if (!val && Object.keys(productFormMethods.formState.errors).includes('priceOverride')) {
      productFormMethods.clearErrors(productFormMethods.formState.name);
    }
    // const productBasePrice = getValues('price');
    console.log(sizeTypeModifierListMethods.fields, '>sizeTypeModifierListMethods.fields');
    if (!sizeTypeModifierListMethods.fields.length) {
      if (!sizeTypeModifierMethods.fields.length) {
        sizeTypeModifierMethods.insert(0, {
          id: uuidv4(),
        });
        let targetConnectedProviders;
        if (currentCategory) {
          targetConnectedProviders = currentCategory.connectedProviders;
        } else {
          targetConnectedProviders = menu.connectedProviders;
        }
        console.log(initialSizeTypeModifier, '>initialSizeTypeModifierinitialSizeTypeModifierinitialSizeTypeModifier');
        initialSizeTypeModifier.forEach((sizeTypeModifier) => {
          Object.keys(targetConnectedProviders).map((modItem) => {
            if (targetConnectedProviders[modItem]) {
              sizeTypeModifier.priceOverride.push({
                context_type: CONTEXT_TYPES.provider,
                context_value: modItem,
                name: modItem,
                price: null,
                pickupPrice: null,
                locked: true,
              });
            }
          });
        });
        sizeTypeModifierListMethods.insert(0, initialSizeTypeModifier);
      }
    }
  };

  const handleAddModifierOption = () => {
    let connectedProviders;
    if (mode === 'edit') {
      connectedProviders = currentProduct?.connectedProviders;
    } else {
      connectedProviders = currentCategory?.connectedProviders;
    }

    const addSizeTypeModifier = {
      name: '',
      priceOverride: [],
      price: 0,
      pickupPrice: '',
      optionSort: sizeTypeModifierListMethods.fields.length,
    };

    Object.keys(connectedProviders).map((modItem) => {
      if (connectedProviders[modItem]) {
        addSizeTypeModifier.priceOverride.push({
          context_type: CONTEXT_TYPES.provider,
          context_value: modItem,
          name: modItem,
          price: '',
          pickupPrice: '',
          locked: true,
        });
      }
    });

    sizeTypeModifierListMethods.append(addSizeTypeModifier);
  };

  const handleDeleteModifierOption = (handleAddModifierOptionIndex, id) => {
    sizeTypeModifierListMethods.remove(handleAddModifierOptionIndex);
    const deletedOption = [];
    if (id) {
      deletedOption.push(id);
      setValue(
        'sizeTypeModifiers[0]',
        { ...getValues().sizeTypeModifiers[0], deletedOptionIds: deletedOption },
        { shouldDirty: true }
      );
    }
  };

  const handleSortEnd = ({ oldIndex, newIndex }) => {
    // const newList = [...sizeTypeModifierListMethods.fields];
    // newList.forEach((item, index) => {});
    // sizeTypeModifierListMethods.update(oldIndex, {
    //   ...sizeTypeModifierListMethods.fields[oldIndex],
    //   optionSort: newIndex,
    // });
    // sizeTypeModifierListMethods.update(newIndex, {
    //   ...sizeTypeModifierListMethods.fields[newIndex],
    //   optionSort: oldIndex,
    // });
    if (oldIndex !== newIndex) {
      sizeTypeModifierListMethods.move(oldIndex, newIndex);
    }
  };

  const handleSaveData = async () => {
    const confirmed = await confirm({
      hideCloseIcon: false,
      hideDiscardBtn: false,
    });
    if (!confirmed) {
      handleValidSubmit(getValues());
      // document.getElementById('productSaveButton').click();
    } else if (confirmed === 'discard') {
      reset(getValues());
    } else {
      window.location.reload();
    }
  };

  const handleDelete = async () => {
    if (currentProduct.modifierId) {
      return handleProductIsModOption(true);
    }
    const target = entityType === 'item' ? 'item' : entityType === 'category' ? 'category' : 'modifier group';
    const confirmed = await confirm({
      // title: `Are you sure you want to delete the ${target}?`,
      title: `Delete ${target}`,
      message: `${t('menu.are_you_sure_delete_the')} ${target}?`,
      cancelText: t('order_view.cancel'),
      confirmText: t('table_ordering.yes_delete'),
      confirmColor: 'primary',
    });
    if (confirmed && mode == 'edit') {
      setLoading(true);
      if (entityType === 'item') {
        dispatch(deleteMenuProduct(productId))
          .then(() => {
            history.push(history.location.pathname);
            dispatch(getMenusV2()).then((res) => {
              res.categories.forEach((item) => {
                dispatch(getCategoryProductsV2(item._id)).finally(() => {
                  dispatch({ type: SET_LOADING, payload: false });
                });
              });
            });
          })
          .finally(() => {
            setLoading(false);
          });
      } else {
        dispatch(deleteModifier(modifierId))
          .then(() => {
            const indexOf = modifierGroupMethods.fields.findIndex((item) => item._id === modifierId);
            if (indexOf != -1) {
              modifierGroupMethods.remove(indexOf);
            }
            if (currentCategory?._id) {
              history.push({
                pathname: history.location.pathname,
                search: `?entityType=item&pid=${productId}&cid=${currentCategory?._id}&mode=edit`,
              });
            } else {
              history.push({
                pathname: history.location.pathname,
                search: `?entityType=item&pid=${productId}&cid=${uuidv4()}&mode=edit`,
              });
            }
          })
          .finally(() => {
            setLoading(false);
          });
      }
    }
  };

  const handleSaveModifierGroup = (delId = '') => {
    setLoading(true);
    const newData = getNextIDsAfterConfirmPopup(delId);
    const modIds = !delId?.search
      ? delId?.length
        ? delId
        : getValues().modifiers.map((item) => item._id)
      : getValues().modifiers.map((item) => item._id);

    if (!delId || !isEdit) {
      return;
    }

    groupModifier(modIds, productId)
      .then((res) => {
        dispatch({ type: SET_PRODUCT_EVENTS, payload: res?.data?.events });
        if (res?.data?.events?.length) {
          localStorage.setItem('SET_MODAL_ACTIONS', ModalOpenStatusEnum.opened);
          dispatch({ type: SET_MODAL_ACTIONS, payload: ModalOpenStatusEnum.opened });
        }
        if (currentCategory?._id || cid) {
          dispatch(getProductById(productId || currentProduct?._id, cid || currentCategory?._id)).then((resp) => {
            if (res?.data?.events?.length) {
              localStorage.setItem('SET_MODAL_ACTIONS', ModalOpenStatusEnum.opened);
              reset(resp, {
                isDirty:
                  productEvents.modalStatus === ModalOpenStatusEnum.cancel ||
                  productEvents.modalStatus === ModalOpenStatusEnum.opened ||
                  res?.data?.events?.length,
                keepDefaultValues:
                  productEvents.modalStatus === ModalOpenStatusEnum.cancel ||
                  productEvents.modalStatus === ModalOpenStatusEnum.opened ||
                  res?.data?.events?.length,
              });
            } else {
              reset(resp);
            }
          });
          // dispatch(getCategoryProductsV2(currentCategory?._id));
        } else {
          dispatch(getProductsAll());
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleResetPriceProvider = () => {
    productPriceOverride.fields.map((item, index) => {
      productPriceOverride.update(index, {
        ...item,
        price: currentProduct.price,
        pickupPrice: currentProduct?.pickupPrice || '',
        locked: item.hasOwnProperty('locked') ? item?.locked : true,
      });
    });
  };

  const handleCleanPriceProvider = () => {
    productPriceOverride.fields.map((item, index) => {
      const target = currentProduct.priceOverride.find((targetItem) => item.context_value === targetItem.context_value);
      let price;
      let pickupPrice;

      if (target?.price || target?.price === 0) {
        price = target?.price;
      } else {
        price = item?.price;
      }

      if (target?.pickupPrice || target?.pickupPrice === 0) {
        pickupPrice = target?.pickupPrice || 0;
      } else {
        pickupPrice = item?.pickupPrice || 0;
      }
      productPriceOverride.update(index, {
        ...item,
        price,
        pickupPrice,
        locked: item.hasOwnProperty('locked') ? item?.locked : true,
      });
      reset(formProduct);
    });
  };

  useEffect(() => {
    setTitle(getValues().name);
  }, [formState]);

  const titleModifier = (title) => {
    setCurrentModifierName(title);
  };

  const handleCloseDialog = (val) => {
    if (typeof val !== 'boolean') {
      return;
    }
    return handleProductIsModOption(val);
  };

  const connectedProvidersByTarget = useMemo(() => {
    if (mode === 'edit') {
      if (currentProduct?.modifierId) {
        return menu?.connectedProviders;
      } else {
        return formProduct?.connectedProviders;
      }
    }
    if (formProduct.modifierId) {
      return menu.connectedProviders;
    }
    if (formProduct.category) {
      return currentCategory?.connectedProviders;
    }

    return formProduct?.connectedProviders;
  }, [formProduct, menu]);

  const onChangePrice = (e, itemIndex) => {
    const { name, value } = e.target;
    const findValue = priceTransform(value);
    if (name.includes('priceOverride') && currentCategory?.connectedProviders[providers.ubereats.srv]) {
      if (findValue) {
        productFormMethods.clearErrors('price');
        productFormMethods.clearErrors('pickupPrice');
      }
    }
    if (name.includes('sizeTypeModifiers') && currentCategory?.connectedProviders[providers.ubereats.srv]) {
      if (name.includes('priceOverride') && findValue) {
        productFormMethods.clearErrors(!name.includes('priceOverride'));
      }
    }
    setValue(name, findValue, { shouldDirty: true });
  };

  const onBlurPrice = (e, itemIndex) => {
    const { name } = e.target;
    const oldPrice = currentProduct?.sizeTypeModifiers?.[0]?.list[itemIndex]?.price;
    const currentPrice = productFormMethods.getValues(`sizeTypeModifiers[0].list[${itemIndex}].price`);
    const oldPrice2 = currentProduct?.price;
    const currentPrice2 = productFormMethods.getValues(`price`);
    const checkProvider =
      isEdit && currentProduct?.priceOverride?.find((provider) => provider?.context_value !== 'main');

    if (
      (((oldPrice || oldPrice === 0) && oldPrice !== currentPrice) ||
        ((oldPrice2 || oldPrice2 === 0) && oldPrice2 !== currentPrice2)) &&
      checkProvider
    ) {
      dispatch({
        type: SET_CHANGE_PRICE_V2,
        payload: {
          changeBasePrice: true,
          title: 'changeBasePriseDialog.title',
          msg: 'changeBasePriseDialog.desc',
          msg2: 'changeBasePriseDialog.desc2',
          price: name === 'price' ? currentPrice2 : currentPrice,
          oldPrice: name === 'price' ? oldPrice2 : oldPrice,
          itemIndex,
          name,
          isConfirm: false,
        },
      });
    } else {
      dispatch({
        type: SET_CHANGE_PRICE_V2,
        payload: {
          changeBasePrice: false,
          title: '',
          msg: '',
          msg2: '',
          price: name === 'price' ? oldPrice2 : oldPrice,
          isConfirm: false,
        },
      });
    }
  };

  const handleErrors = (formState, formAddOption) => {
    let haveErrors = false;
    const formErrorsCopy = { ...formErrors };
    if (!formState.name && !formAddOption) {
      haveErrors = true;
      set(formErrorsCopy, 'name', { error: true, message: 'field_is_required_' });
    }
    formState.list.forEach((listItem, listItemIndex) => {
      if (!listItem.name) {
        haveErrors = true;
        set(formErrorsCopy, `list[${listItemIndex}].name`, { error: true, message: 'field_is_required_' });
      }
      if (listItem.price === '' && listItem.price !== 0) {
        haveErrors = true;
        set(formErrorsCopy, `list[${listItemIndex}].price`, { error: true, message: 'field_is_required_' });
      }
      if (formState?.products) {
        formState.products.map((item) => {
          if (currentProduct?._id === item._id) {
            if (currentProduct?.connectedProviders[providers.ubereats.srv] && listItem.price > 374) {
              haveErrors = true;
              set(formErrorsCopy, `list[${listItemIndex}].price`, {
                error: true,
                message: 'price_$375.00_for_ubereats_',
              });
            } else {
              haveErrors = false;
              set(formErrorsCopy, `list[${listItemIndex}].price`, {
                error: false,
                message: '',
              });
            }
          }
        });
      }

      listItem.priceOverride.forEach((priceOverrideItem, priceOverrideItemIndex) => {
        if (priceOverrideItem.context_type === CONTEXT_TYPES.modifierItem && priceOverrideItem.name === '') {
          haveErrors = true;
          set(formErrorsCopy, `list[${listItemIndex}].priceOverride[${priceOverrideItemIndex}].name`, {
            error: true,
            message: t('field_is_required_'),
          });
        }
        if (priceOverrideItem.price === '' && priceOverrideItem.price !== 0) {
          haveErrors = true;
          set(formErrorsCopy, `list[${listItemIndex}].priceOverride[${priceOverrideItemIndex}].name`, {
            error: true,
            message: t('field_is_required_'),
          });
        }
      });
    });
    updateFormErrors(formErrorsCopy);
    return haveErrors;
  };

  const handleCancel = () => {
    history.push({
      pathname: history.location.pathname,
      search: '',
      state: {
        cancel: true,
      },
    });
  };

  useEffect(() => {
    if ((changeBasePriceData?.price && changeBasePriceData.isConfirm) || changeBasePriceData?.oldPrice) {
      if (changeBasePriceData?.name === 'price') {
        productFormMethods.setValue(`price`, changeBasePriceData?.price);
        getValues()?.priceOverride?.forEach((_, index) => {
          productFormMethods.setValue(`priceOverride[${index}].locked`, true, { shouldDirty: true });
        });
      } else if (changeBasePriceData?.name === `sizeTypeModifiers[0].list[${changeBasePriceData?.itemIndex}].price`) {
        productFormMethods.setValue(
          `sizeTypeModifiers[0].list[${changeBasePriceData?.itemIndex}].price`,
          changeBasePriceData?.price
        );
        getValues().sizeTypeModifiers[0]?.list?.forEach((item, index) => {
          item?.priceOverride?.forEach((_, provideIndex) => {
            productFormMethods.setValue(
              `sizeTypeModifiers[0].list[${index}].priceOverride[${provideIndex}].locked`,
              true,
              { shouldDirty: true }
            );
          });
        });
      }
    }
  }, [changeBasePriceData]);

  return (
    <Box sx={{ height: '100%', position: 'relative', overflow: 'hidden' }}>
      {/* Fixed Header */}
      {loading && <Preloader overlay={true} />}
      <Box>
        {productEvents.eventData[0]?.tag === 'productZeroPrice' && (
          <ConfirmProductActions
            formData={{ ...getValues(), price: 0, checkZeroPrice: false }}
            handleSubmit={handleValidSubmit}
          />
        )}
        <FormHeader
          title={title}
          currentModifierName={currentModifierName}
          setCurrentModifierName={setCurrentModifierName}
          modifierFormState={modifierFormState}
          modifierMethods={modifierGroupMethods}
          // currentModifierName={currentModifierName}
          currentModifier={currentModifier}
          currentProduct={currentProduct}
          setTabValue={setTabValue}
          productId={productId}
          isDirty={isDirty}
          setIsDirty={setIsDirty}
          cid={cid}
          handleErrors={handleErrors}
          updateFormErrors={updateFormErrors}
          data={getValues()}
          formProduct={formProduct}
          entityType={entityType}
          product={currentProduct}
          category={currentCategory}
          mode={mode}
          modifier={showModifier}
          setLoading={setLoading}
          productFormMethods={productFormMethods}
          handleValidSubmit={handleValidSubmit}
          setLoadingDuplicate={setLoadingDuplicate}
          handleDelete={handleDelete}
          menu={menu}
          connectedProviders={connectedProvidersByTarget}
        />
      </Box>
      {showModifier ? (
        <ModifierGroupForm
          setTitleModifier={titleModifier}
          modifierMethods={modifierGroupMethods}
          currentModifier={currentModifier}
          currentProduct={currentProduct}
          currentCategory={currentCategory}
          setIsDirty={setIsDirty}
          isDirty={loadingDuplicate ? false : isDirty}
          setLoadingDuplicate={setLoadingDuplicate}
          setModifierFormState={setModifierFormState}
          setTabValue={changeProductTab}
          setCurrentModifier={setCurrentModifier}
          formErrors={formErrors}
          updateFormErrors={updateFormErrors}
          isChanged={isChanged}
          setIsChanged={setIsChanged}
          handleErrors={handleErrors}
          loadingDuplicate={loadingDuplicate}
        />
      ) : (
        <FormProvider {...productFormMethods} sx={{ height: '100%' }} component="div">
          {isEdit && (
            <UpdateAvailability
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              availability={getValues()}
              currentBusiness={currentBusiness}
              dispatch={dispatch}
              register={register}
              formProduct={formProduct}
              title="item"
              reset={reset}
            />
          )}

          <form
            style={{ height: '100%' }}
            onSubmit={productFormMethods.handleSubmit(handleValidSubmit, (err) => {
              console.log('err: ', err);
            })}
          >
            <ConfirmPromptModal
              handleConfirm={productFormMethods.handleSubmit(handleValidSubmit)}
              submitData={getValues()}
              hasUnsavedChanges={menuAvailable && formState.isDirty}
              formState={productFormMethods}
              isLoading={loading}
            />

            {/* Scrolled content */}
            <Box sx={{ height: 'calc(100% - 176px)', overflowY: 'auto', pb: 3, pt: 4 }}>
              <Box sx={{ px: 4 }}>
                <TabsList onChange={changeProductTab} value={tabValue} variant="scrollable" scrollButtons="auto">
                  <StyledTab label={t('menu.basic')} />
                  <StyledTab label={t('menu.modifiers_options')} />
                  <StyledTab label={t('menu.advanced')} />
                  <StyledTab disabled={!formProduct?.modifiers?.length} label={t('menu.Pre-Selected Options')} />
                </TabsList>
              </Box>
              <TabPanel
                value={tabValue}
                index={0}
                content={
                  <Private permission="MENU_EDIT" disabled={true}>
                    <ProductFormBasic
                      imageGetvalue={setImageGetvalue}
                      isInOverview={isInOverview}
                      changeIsOnePrice={changeIsOnePrice}
                      readOnly={readOnly}
                      mode={mode}
                      onChangePrice={onChangePrice}
                      onBlurPrice={onBlurPrice}
                      connectedProvidersByTarget={connectedProvidersByTarget}
                      handleClickOpen={handleClickOpen}
                      data={getValues()}
                      formProduct={formProduct}
                      currentProductSizeTypeModifiers={currentProduct?.sizeTypeModifiers}
                      productPriceOverride={productPriceOverride.fields}
                      productFormMethods={productFormMethods}
                      handleAddModifierOption={handleAddModifierOption}
                      handleDeleteModifierOption={handleDeleteModifierOption}
                      sizeTypeModifierFields={sizeTypeModifierMethods.fields}
                      sizeTypeModifierListFields={sizeTypeModifierListMethods.fields}
                      handleSortEnd={handleSortEnd}
                      control={control}
                      handleResetPriceProvider={handleResetPriceProvider}
                      handleCleanPriceProvider={handleCleanPriceProvider}
                      currentCategory={currentCategory}
                      menu={menu}
                      currentProduct={currentProduct}
                      loading={loading}
                      initialData={initialData}
                      setDisable={setDisable}
                    />
                  </Private>
                }
              />
              <TabPanel
                value={tabValue}
                index={1}
                content={
                  <ModifierOptions
                    cid={cid}
                    handleSaveModifierGroup={handleSaveModifierGroup}
                    // navigateToModifierForm={navigateToModifierForm}
                    currentProduct={currentProduct}
                    currentCategory={currentCategory}
                    modifierGroupMethods={modifierGroupMethods}
                  />
                }
              />
              <TabPanel
                value={tabValue}
                index={2}
                content={
                  <Private permission="MENU_EDIT" disabled={true}>
                    <ProductFormDetails
                      setErrorData={setErrorData}
                      errorData={errorData}
                      setDisable={setDisable}
                      productFormMethods={productFormMethods}
                      reset={reset}
                      data={getValues()}
                      setValue={setValue}
                      connectedProviders={connectedProvidersByTarget}
                    />
                  </Private>
                }
              />
              <TabPanel
                value={tabValue}
                index={3}
                content={<DefaultSelections productFormMethods={productFormMethods} />}
              />
            </Box>
            {/* Fixed Footer */}
            <Box>
              <FormFooter
                isDisable={formState.isDirty}
                disabled={(disable && !formState.isDirty) || !menuAvailable}
                mode={mode}
                handleSaveModifierGroup={handleSaveModifierGroup}
                tabValue={tabValue}
                isEdit={isEdit}
                handleClickOpen={handleClickOpen}
                handleCancel={handleCancel}
              />
            </Box>
          </form>
        </FormProvider>
      )}

      <Dialog onClose={handleCloseDialog} open={productIsModOption} PaperProps={{ sx: { p: 3 } }}>
        <IconButton
          sx={{ position: 'absolute', top: 0, right: 0 }}
          aria-label="close"
          onClick={() => handleCloseDialog(false)}
        >
          <CloseIcon />
        </IconButton>
        <DialogTitle sx={{ textAlign: 'center' }}>{t('menu.you_can_not_delete_modifier_option')}</DialogTitle>
        {/* <Typography sx={{ fontSize: '14px', fontWeight: 500, textAlign: 'center', color: '#3D4350', mt: 1 }}>
          You can not delete this product as it is used as a modifier option.
        </Typography> */}
      </Dialog>
      <BasePriceConfirmDialog open={basePriceConfirmDialogOpen} onClose={handleCloseBasePriceConfirmModal} />
    </Box>
  );
};

export default ProductForm;
