import React from 'react';
import { SortableElement } from 'react-sortable-hoc';
import { Box } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import Private from 'components/Private';
import DragHandle from 'components/DragHandle';
import Preloader from 'components/Preloader';
import ShowModifierOptions from 'components/MenuNew/FormView/ModifierFormGroup/ShowModifierOptions';
import useModifierById from 'hooks/useModifierById';

const SortableModiferItem = SortableElement(
  ({ value, handleEditModifier, onDelete, loadingModifierOption, currentProduct }) => {
    const { currentModifier, loadingModOpt } = useModifierById(value?._id);

    return (
      <Box
        sx={{
          border: '1px solid #eee',
          borderRadius: '6px',
          mt: 2,
        }}
      >
        <Box
          key={value.id}
          variant="contained"
          sx={{
            background: '#FFD66E',
            border: 'none',
            color: 'black',
            mr: 1,
            padding: '8px 12px',
            borderRadius: '3px',
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            justifyContent: 'space-between',
          }}
        >
          <Box>
            <DragHandle className="pr-2  d-inline-block modifer-drag" />
            {value.name} {currentModifier?.title && `( ${currentModifier?.title} )`}
          </Box>
          <Box>
            <EditIcon
              sx={{ fontSize: '18px', mx: 1, cursor: 'pointer', '&:hover': { color: 'rgb(0 0 0 / 54%)' } }}
              onClick={() => handleEditModifier(value)}
            />
            <Private permission="MENU_EDIT">
              <CloseIcon
                sx={{ fontSize: '18px', mx: 1, cursor: 'pointer', '&:hover': { color: 'rgb(0 0 0 / 54%)' } }}
                onClick={() => onDelete(value)}
              />
            </Private>
          </Box>

          {loadingModifierOption && <Preloader overlay={true} />}
        </Box>
        <ShowModifierOptions
          currentProduct={currentProduct}
          loading={loadingModOpt}
          currentModifier={currentModifier}
        />
      </Box>
    );
  }
);

export default SortableModiferItem;
